// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-gruesse-js": () => import("./../src/pages/gruesse.js" /* webpackChunkName: "component---src-pages-gruesse-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-schwimmen-zuhause-js": () => import("./../src/pages/schwimmen-zuhause.js" /* webpackChunkName: "component---src-pages-schwimmen-zuhause-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../src/pages/ueber-mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */)
}

